body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-grid {
  text-align: center;
}

.home-style {
  margin: 0 auto;
  width: 50%;
}

.nav-style {
  text-align: center;
}

.logo-style {
  width: 120px;
  text-align: center;
  margin-left: 10px;
}

.mini-logo-style {
  width: 100px;
  text-align: center;
}

.flex-end-style {
  align: center;
}

.view-style {
  background-color: black;
}

.button-style {
  background: linear-gradient(45deg, #006042 30%, #218a45 90%);
}

.menu-button-style {
  background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
  borderradius: 3;
  border: 0;
  color: white;
  height: 48;
  padding: 0 30px;
  boxshadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
}

.big-button {
  -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.1s ease 0s;
  cursor: pointer;
  border-radius: 15px;
  width: 200px;
  height: 270px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.landing-page-button {
  -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.1s ease 0s;
  cursor: pointer;
  border-radius: 15px;
  width: 140px;
  height: 140px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.landing-page-button-big {
  -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.1s ease 0s;
  cursor: pointer;
  border-radius: 15px;
  width: 310px;
  height: 140px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.class-page-button {
  -webkit-box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.1s ease 0s;
  cursor: pointer;
  border-radius: 15px;
  width: 310px;
  padding-bottom: 15px;
  margin-top: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.purchase-with-scones-button-big {
  -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.1s ease 0s;
  cursor: pointer;
  border-radius: 10px;
  width: 200px;
  height: 50px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.mypage-card {
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease 0s;
  border-radius: 5px;
  margin-top: 15px;
  width: auto;
  height: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-color: white;
  position: relative;
  z-index: 100;
}

.order-list-card {
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease 0s;
  border-radius: 10px;
  margin-top: 15px;
  width: auto;
  height: auto;
  align-content: center;
  background-color: white;
  position: relative;
  z-index: 100;
  width: 90%;
  max-width: 470px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.coupon-list-card {
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease 0s;
  border-radius: 10px;
  margin-top: 15px;
  width: auto;
  height: auto;
  align-content: center;
  background-color: white;
  width: 90%;
  max-width: 470px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.justify-between {
  justify-content: space-between;
}

.main-card {
  -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.1s ease 0s;
  border-radius: 15px;
  width: 90%;
  height: 155px;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-color: white;
  position: relative;
  top: -50px;
  padding-top: 5px;
  z-index: 5;
}

.nav-text {
  font-size: 12px;
  padding: 10px;
}

.nav-selected-text {
  font-size: 12px;
  padding: 10px;
  font-weight: bold;
  border-bottom: 3px solid #006042;
}

.bottom-nav-text {
  font-size: 10px;
  padding: 10px;
}

.bottom-nav-selected-text {
  font-size: 11px;
  padding: 10px;
  font-weight: bold;
  color: #006042;
}

.top-nav {
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: white;
}

.bottom-nav {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: white;
}

.main-view {
  padding-top: 140px;
  padding-bottom: 80px;
}

.disable-double-tabs {
  touch-action: manipulation;
}

.regular-view {
  padding-top: 140px;
  padding-bottom: 140px;
}

.main-background {
  background-color: green;
}

.Mui-selected {
  background-color: white;
  color: #006042;
}

.Mui-focusVisible {
  color: red;
}

.btn-bottom-admin-fixed-invisible {
  display: flex;
  position: fixed;
  text-align: center;
  align-items: center;
  justify-content: center;
  bottom: 160px;
  height: auto;
  width: 75%;
  max-width: 500px;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  background-color: #3d3d3d;
  z-index: 300;
  opacity: 0;
  transition: all 0.2s ease 0s;
}

.btn-bottom-admin-notice-fixed {
  display: flex;
  position: fixed;
  text-align: center;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  height: auto;
  width: 75%;
  max-width: 500px;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  background-color: #3d3d3d;
  z-index: 300;
  opacity: 100;
  transition: all 0.2s ease 0s;
}

.btn-bottom-delivery-notice-fixed-invisible {
  display: flex;
  position: fixed;
  text-align: center;
  align-items: center;
  justify-content: center;
  top: 160px;
  height: auto;
  width: 75%;
  max-width: 500px;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  background-color: #3d3d3d;
  z-index: 300;
  opacity: 0;
  transition: all 0.2s ease 0s;
}

.btn-bottom-delivery-notice-fixed {
  display: flex;
  position: fixed;
  text-align: center;
  align-items: center;
  justify-content: center;
  top: 160px;
  height: auto;
  width: 75%;
  max-width: 500px;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  background-color: #3d3d3d;
  z-index: 300;
  opacity: 100;
  transition: all 0.2s ease 0s;
}

.btn-bottom-delivery-discount-fixed-upper {
  display: flex;
  position: fixed;
  text-align: center;
  align-items: center;
  justify-content: center;
  top: 115px;
  height: auto;
  width: 75%;
  max-width: 500px;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 135, 117, 0.95);
  z-index: 300;
  opacity: 100;
  transition: all 0.2s ease 0s;
}

.btn-bottom-delivery-discount-fixed-upper-red {
  display: flex;
  position: fixed;
  text-align: center;
  align-items: center;
  justify-content: center;
  top: 115px;
  height: auto;
  width: 75%;
  max-width: 500px;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255, 72, 59, 0.95);
  z-index: 300;
  opacity: 100;
  transition: all 0.2s ease 0s;
}

.btn-bottom-delivery-discount-fixed {
  display: flex;
  position: fixed;
  text-align: center;
  align-items: center;
  justify-content: center;
  top: 160px;
  height: auto;
  width: 75%;
  max-width: 500px;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 135, 117, 0.95);
  z-index: 300;
  opacity: 100;
  transition: all 0.2s ease 0s;
}

.btn-bottom-delivery-discount-fixed-red {
  display: flex;
  position: fixed;
  text-align: center;
  align-items: center;
  justify-content: center;
  top: 160px;
  height: auto;
  width: 75%;
  max-width: 500px;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255, 72, 59, 0.95);
  z-index: 300;
  opacity: 100;
  transition: all 0.2s ease 0s;
}

.btn-bottom-notice-sticky {
  display: flex;
  position: sticky;
  text-align: center;
  align-items: center;
  justify-content: center;
  bottom: 100px;
  height: auto;
  width: 75%;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  background-color: #3d3d3d;
  z-index: 300;
}

.btn-bottom-big-sticky {
  display: flex;
  position: sticky;
  text-align: center;
  align-items: center;
  justify-content: center;
  bottom: 45px;
  height: auto;
  width: 100%;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 135, 117, 0.95);
  cursor: pointer;
  z-index: 300;
}

.btn-bottom-sticky {
  display: flex;
  position: sticky;
  text-align: center;
  align-items: center;
  justify-content: center;
  bottom: 50px;
  height: 45px;
  width: 180px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 135, 117, 0.95);
  border-radius: 8px;
  cursor: pointer;
  z-index: 300;
}

.first-set-alarm {
  display: flex;
  position: sticky;
  bottom: 60px;
  height: 70px;
  width: auto;
  margin-left: 10px;
  background-color: rgba(0, 135, 117, 0.95);
  border-radius: 10px;
  align-items: center;
  z-index: 300;
  opacity: 100;
  transition: all 0.2s ease 0s;
}

.btn-bottom-alarm {
  display: flex;
  position: sticky;
  bottom: 55px;
  border-radius: 100px;
  margin-left: 20px;
  height: auto;
  width: auto;
  align-items: center;
  cursor: pointer;
  z-index: 300;
}

.btn-bottom-alarm-disappear {
  display: flex;
  position: sticky;
  bottom: 55px;
  border-radius: 100px;
  margin-left: 20px;
  height: auto;
  width: auto;
  align-items: center;
  cursor: pointer;
  z-index: 300;
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.2s ease 0s;
}

.btn-bottom-cart {
  display: flex;
  position: sticky;
  bottom: 60px;
  height: 50px;
  width: auto;
  margin-right: 15px;
  margin-bottom: 5px;
  margin-left: auto;
  background-color: #3d3d3d;
  border-radius: 100px;
  align-items: flex-end;
  z-index: 300;
}

.btn-bottom-cart-able {
  display: flex;
  position: sticky;
  bottom: 60px;
  height: 50px;
  width: auto;
  margin-right: 15px;
  margin-bottom: 5px;
  margin-left: auto;
  background-color: rgba(0, 135, 117, 0.95);
  border-radius: 100px;
  align-items: flex-end;
  z-index: 300;
}

.btn-bottom-cart-able:hover {
  -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.1s ease 0s;
  cursor: pointer;
}

.btn-bottom-cart-able:active {
  -webkit-box-shadow: 0px 5px 5px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 5px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 5px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.1s ease 0s;
  cursor: pointer;
}

.modal-btn-bottom-big {
  height: auto;
  width: 85%;
  max-width: 550px;
  margin: 20px;
  background-color: rgba(0, 135, 117, 0.95);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.btn-bottom-big-disabled {
  position: fixed;
  bottom: 56px;
  height: auto;
  width: 100%;
  max-width: 550px;
  background-color: #b5b5b5;
  text-align: center;
}

.btn-bottom-big {
  position: fixed;
  bottom: 45px;
  height: auto;
  width: 100%;
  max-width: 550px;
  background-color: rgba(0, 135, 117, 0.95);
  text-align: center;
}

.btn-bottom-big-alt {
  height: auto;
  width: 100%;
  padding-top: 14px;
  background-color: gray;
  border-radius: 5px;
  text-align: center;
  align-self: center;
}

.btn-bottom-big-black {
  height: auto;
  width: 100%;
  padding-top: 14px;
  background-color: black;
  border-radius: 5px;
  text-align: center;
  align-self: center;
}

.btn-bottom-big:hover {
  -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.1s ease 0s;
  cursor: pointer;
}

.btn-bottom-big:active {
  -webkit-box-shadow: 0px 5px 5px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 5px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 5px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.1s ease 0s;
  cursor: pointer;
}

.btn-big {
  height: auto;
  width: 90%;
  background-color: rgba(0, 135, 117, 0.95);
  text-align: center;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
}

.btn-very-big {
  height: auto;
  width: 85%;
  max-width: 550px;
  background-color: rgba(0, 135, 117, 0.95);
  text-align: center;
  padding: 15px;
  border-radius: 3px;
  cursor: pointer;
}

.btn-very-big-disabled {
  height: auto;
  width: 85%;
  max-width: 550px;
  background-color: gray;
  text-align: center;
  padding: 15px;
  border-radius: 3px;
}

.btn-big-normal {
  height: auto;
  width: 85%;
  max-width: 550px;
  background-color: rgba(0, 135, 117, 0.95);
  text-align: center;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
}

.btn-big-normal-disabled {
  height: auto;
  width: 85%;
  max-width: 550px;
  background-color: #b5b5b5;
  text-align: center;
  padding: 5px;
  border-radius: 3px;
}

.btn-very-bottom-big-in-sheet {
  position: fixed;
  bottom: 0px;
  height: auto;
  width: 100%;
  max-width: 550px;
  background-color: rgba(0, 135, 117, 0.95);
  text-align: center;
  cursor: pointer;
}

.btn-very-bottom-big-outlined {
  position: fixed;
  bottom: 0px;
  height: auto;
  width: 100%;
  max-width: 550px;
  border: 1px solid #006042;
  text-align: center;
  background-color: white;
  cursor: pointer;
}

.btn-very-bottom-big-disabled {
  position: fixed;
  bottom: 0px;
  height: auto;
  width: 100%;
  max-width: 550px;
  background-color: #b5b5b5;
  text-align: center;
}

.btn-upper-bttome-tab-big {
  display: flex;
  height: 45px;
  width: 100%;
  max-width: 550px;
  background-color: rgba(0, 135, 117, 0.95);
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  margin-top: 10px;
}

.btn-very-bottom-big-center {
  position: fixed;
  bottom: 0px;
  height: auto;
  width: 100%;
  max-width: 600px;
  background-color: rgba(0, 135, 117, 0.95);
  text-align: center;
}

.btn-very-bottom-big-center:hover {
  -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.1s ease 0s;
  cursor: pointer;
}

.btn-very-bottom-big-center:active {
  -webkit-box-shadow: 0px 5px 5px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 5px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 5px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.1s ease 0s;
  cursor: pointer;
}

.btn-very-bottom-big-loading {
  position: fixed;
  display: flex;
  bottom: 0px;
  height: 50px;
  width: 100%;
  max-width: 550px;
  background-color: rgba(0, 135, 117, 0.95);
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-very-bottom-big {
  position: fixed;
  bottom: 0px;
  height: auto;
  width: 100%;
  max-width: 550px;
  background-color: rgba(0, 135, 117, 0.95);
  text-align: center;
}

.btn-very-bottom-big:hover {
  -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.1s ease 0s;
  cursor: pointer;
}

.btn-very-bottom-big:active {
  -webkit-box-shadow: 0px 5px 5px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 5px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 5px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.1s ease 0s;
  cursor: pointer;
}

.regular-main-image {
  position: fixed;
  top: 350px;
  z-index: 100;
}

.direction-row-div {
  flex-direction: row;
  display: flex;
}

.direction-column-div {
  flex-direction: column;
  display: flex;
}

.agreement-div {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.close-button {
  flex-direction: row;
  display: flex;
  cursor: pointer;
}

.big-back-button-container {
  position: fixed;
  top: 0px;
  height: 100px;
  background-color: white;
  z-index: 500;
  max-width: 600px;
  justify-content: center;
}

.back-button-container {
  position: fixed;
  top: 0px;
  height: 60px;
  background-color: white;
  z-index: 500;
  max-width: 600px;
  justify-content: center;
}

.sheet-header {
  height: 40px;
  margin-top: 20px;
  background-color: white;
  justify-content: center;
}

.back-button {
  flex-direction: row;
  display: flex;
  cursor: pointer;
}

.delivery-main-view {
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  background-color: white;
  top: -20px;
  width: 100%;
}

.coupon-tabs {
  position: fixed;
  top: 60px;
  max-width: 600px;
}

.coupon-nav-selected-text {
  font-size: 13px;
  padding: 0px;
  font-weight: bold;
  color: white;
}

.coupon-nav-selected-view {
  display: flex;
  background-color: #006042;
  justify-content: center;
  align-items: flex-start;
  width: 45%;
  cursor: pointer;
}

.coupon-nav-text {
  font-size: 13px;
  padding: 0px;
}

.coupon-nav-view {
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: flex-start;
  width: 45%;
  cursor: pointer;
  box-shadow: 0 0 0 1px #e8e8e8 inset;
}

.item-nav-selected-text {
  font-size: 13px;
  padding: 0px;
  font-weight: bold;
  color: white;
}

.item-nav-selected-view {
  display: flex;
  background-color: #006042;
  justify-content: center;
  align-items: flex-start;
  width: calc(100% / 3);
  cursor: pointer;
  z-index: 999;
}

.item-nav-text {
  font-size: 13px;
  padding: 0px;
}

.item-nav-view {
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: flex-start;
  width: calc(100% / 3);
  margin-right: -3px;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  z-index: 999;
}
.sticky-view {
  position: sticky;
  top: 111px;
  z-index: 10;
}

.sticky-mobile-view {
  position: sticky;
  top: 108px;
  z-index: 10;
}
.auto-width-120 {
  width: 120%;
}

.auto-width-100 {
  width: 100%;
}

.auto-width-95 {
  width: 95%;
}

.auto-width-90 {
  width: 90%;
}

.auto-width-85 {
  width: 85%;
}

.auto-width-80 {
  width: 80%;
}

.auto-width-70 {
  width: 70%;
}

.auto-width-60 {
  width: 60%;
}

.auto-width-50 {
  width: 50%;
}

.auto-width-45 {
  width: 45%;
}

.auto-width-40 {
  width: 40%;
}

.auto-width-10 {
  width: 10%;
}

.auto-width {
  width: 300px;
}

.auto-width-height {
  width: auto;
  height: auto;
}

.break-word {
  word-break: keep-all;
}

.max-scone-number {
  flex-direction: row;
  display: flex;
  position: absolute;
  bottom: 40px;
  right: 0px;
  font-size: 10px;
  color: red;
}

.normal-add-round-button {
  background-color: rgba(0, 135, 117, 0.95);
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
  cursor: pointer;
}

.normal-add-round-disable-button {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.add-remove-round-button {
  background-color: rgba(0, 135, 117, 0.95);
  border-radius: 5px;
  padding: 2px;
  margin: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
  cursor: pointer;
}

.counter-button-div {
  flex-direction: row;
  display: flex;
  border-top: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
  border-right: 1px solid #cfcfcf;
  border-left: 1px solid #cfcfcf;
}

.counter-button {
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.counter-style {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 32px;
  height: 32px;
}

.normal-button-set-div {
  height: auto;
  width: auto;
  margin-left: 0px;
  margin-right: auto;
}

.normal-little-bit-small-button {
  height: auto;
  width: 120px;
  margin-left: 0px;
  margin-right: 10px;
  background-color: rgba(0, 135, 117, 0.95);
  text-align: center;
  cursor: pointer;
  padding: 3px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
}

.normal-very-small-button-red {
  height: auto;
  width: 90px;
  margin-left: 0px;
  margin-right: 10px;
  background-color: #eb4034;
  text-align: center;
  cursor: pointer;
  padding: 3px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
}

.normal-very-small-button {
  height: auto;
  width: 90px;
  margin-left: 0px;
  margin-right: 10px;
  background-color: rgba(0, 135, 117, 0.95);
  text-align: center;
  cursor: pointer;
  padding: 3px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
}

.normal-very-small-button-outlined {
  height: auto;
  width: 90px;
  margin-left: 0px;
  margin-right: 10px;
  background-color: white;
  border: 1px solid rgba(0, 135, 117, 0.95);
  text-align: center;
  cursor: pointer;
  padding: 3px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
}

.normal-very-small-button-disabled {
  height: auto;
  width: 90px;
  margin-left: 0px;
  margin-right: 10px;
  background-color: gray;
  text-align: center;
  padding: 3px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
}

.normal-small-button {
  height: auto;
  width: auto;
  margin-left: 0px;
  margin-right: 10px;
  background-color: rgba(0, 135, 117, 0.95);
  border: 1px solid rgba(0, 135, 117, 0.95);
  text-align: center;
  cursor: pointer;
  padding: 8px;
  padding-right: 12px;
  padding-left: 12px;
  border-radius: 3px;
  margin-top: 5px;
}

.normal-small-button-disabled {
  height: auto;
  width: auto;
  margin-left: 0px;
  margin-right: 10px;
  background-color: gray;
  border: 1px solid rgba(0, 135, 117, 0.95);
  text-align: center;
  padding: 8px;
  padding-right: 12px;
  padding-left: 12px;
  border-radius: 3px;
  margin-top: 5px;
}

.normal-small-button-outlined {
  height: auto;
  width: auto;
  margin-left: 0px;
  margin-right: 10px;
  background-color: white;
  border: 1px solid rgba(0, 135, 117, 1);
  text-align: center;
  cursor: pointer;
  padding: 8px;
  padding-right: 12px;
  padding-left: 12px;
  border-radius: 3px;
  margin-top: 5px;
}

.normal-button {
  height: auto;
  width: 82%;
  margin-left: auto;
  margin-right: auto;
  max-width: 550px;
  background-color: rgba(0, 135, 117, 0.95);
  text-align: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 3px;
  margin-top: 10px;
}

.no-selection {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.round-icon {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 55px;
  margin: 5px;
  background-color: white;
  justify-content: center;
  align-items: center;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 0.9;
  transition: opacity 200ms ease-in;
}

.fade-exit {
  opacity: 0.9;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in;
}

.enter-address {
  display: flex;
  width: 95%;
  height: 40px;
  margin-top: 30px;
  margin-bottom: 20px;
  cursor: pointer;
  border: 1px solid #adadad;
  border-radius: 5px;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.close-icon {
  position: relative;
  top: 18px;
  right: 12px;
  cursor: pointer;
}

.relative-position {
  position: relative;
}

.remove-item-modal {
  flex-direction: column;
  display: flex;
  background-color: white;
  outline: none !important;
  border-radius: 20px;
  padding: 30px;
  align-items: center;
}

.scone-image-modal {
  flex-direction: column;
  display: flex;
  background-color: white;
  outline: none !important;
  border-radius: 50px;
  padding: 10px;
  align-items: center;
}

.scone-image {
  width: 100%;
  height: auto;
}

.popup-image-modal {
  flex-direction: column;
  display: flex;
  background-color: white;
  outline: none !important;
  padding: 10px;
  align-items: center;
  max-width: 400px;
  height: auto;
}

.popup-image {
  width: 100%;
  height: auto;
}

.popup-close-button {
  cursor: pointer;
  position: absolute;
  top: -45px;
  right: 0px;
  width: 50px;
  height: 50px;
  padding: 5px;
  border-radius: 25px;
  background-color: white;
}

.top-right-close-button {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 25px;
}

.modal-button-filled {
  display: flex;
  width: 120px;
  height: 50px;
  border-radius: 10px;
  background-color: #006042;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 20px;
}

.modal-one-button-filled {
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #006042;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal-button-outlined {
  display: flex;
  width: 120px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #006042;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  cursor: pointer;
}

.enter-detail-address {
  flex-direction: column;
  display: flex;
  background-color: white;
  outline: none !important;
  width: 450px;
  height: auto;
  align-items: center;
  position: relative;
}

.modal-outside-wrap {
  overflow: visible;
}

.modal-outside-button {
  overflow: auto;
}

.input-style {
  width: 85%;
}

.cart-address {
  width: 95%;
  align-content: center;
  margin-left: auto;
}

.delivery-address {
  flex-direction: column;
  display: flex;
  width: 85%;
  align-content: center;
  padding: 15px;
  margin: 10px;
  background-color: #ededed;
  border-radius: 10px;
  cursor: pointer;
}
.edit-button {
  flex-direction: row;
  display: flex;
  position: relative;
  top: 3px;
  right: 3px;
  bottom: auto;
  left: auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.underline-text {
  text-decoration: underline;
  cursor: pointer;
}

.none-underline-text {
  text-decoration: none !important;
  color: black;
  display: flex;
}

.flex-start {
  text-align: left;
  width: 85%;
}

.address-info-wrap {
  display: inline-flex;
  font-size: 12px;
  inline-size: 100%;
  margin-bottom: 3px;
}

.address-info-title {
  display: inline-flex;
  font-size: 12px;
  inline-size: 60px;
}

.address-info-content {
  display: inline-flex;
  font-size: 12px;
  inline-size: 80%;
}

.login-main-view {
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  top: 18%;
}

.naver-login-button {
  display: flex;
  background-color: #03c75a;
  height: auto;
  margin-bottom: 20px;
  width: 250px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}
.naver-icon-style {
  width: 40px;
  height: 40px;
}

.kakao-login-button {
  display: flex;
  background-color: #ffe812;
  height: auto;
  margin-bottom: 50px;
  width: 250px;
  padding: 15px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}
.kakao-icon-style {
  width: 20px;
  height: 20px;
}

.modal-sheet {
  display: flex;
  margin: auto;
  max-width: 600px;
}

.bg-gray {
  background-color: #f0f2f5;
}

.center-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.align-left {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.justify-center {
  display: flex;
  align-content: center;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.delivery-location {
  margin: 20px;
  margin-bottom: 0px;
  padding: 20px;
  border: 2px solid #878787;
  border-radius: 5px;
}

.delivery-selection {
  margin: 20px;
  margin-bottom: 0px;
  padding: 20px;
  border: 2px solid #dedede;
  border-radius: 5px;
  cursor: pointer;
}

.delivery-selection-selected {
  margin: 20px;
  margin-bottom: 0px;
  padding: 20px;
  border: 2px solid #006042;
  border-radius: 5px;
  cursor: pointer;
}

.badge-style {
  display: inline-flex;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 20px;
  padding: 3px;
  width: 80px;
  background-color: rgba(0, 135, 117, 0.95);
  justify-content: center;
}

.right-top-position {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  width: 100%;
}

.no-items-main-view {
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.order-list {
  margin: 10px;
  border: 1px solid gray;
  border-radius: 10px;
}

.one-point-three-line-height {
  display: inline-block;
  line-height: 1.3;
}

.one-line {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.3;
  max-height: 1.3em;
  text-align: left;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.two-line {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.5;
  max-height: 3em;
  text-align: left;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.no-line {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.5;
  text-align: left;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.star-rating {
  width: 100%;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.review-text-input {
  width: 92%;
}

.input-file-button {
  padding-bottom: 25px;
  padding-top: 35px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: white;
  border-radius: 4px;
  color: #006042;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #006042;
}

.input-docs-file-button {
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: white;
  border-radius: 4px;
  color: #006042;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #006042;
}

.input-file-button-div {
  margin-left: 3px;
  margin-top: 30px;
}

.div-wrap {
  display: inline-flex;
  flex-wrap: wrap;
}

.cancel-icon {
  position: absolute;
  right: -2px;
  top: -2px;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
}

.max-width-560 {
  max-width: 560px;
}

.vertical-center-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-align {
  display: flex;
  justify-content: center;
  align-content: center;
}

.self-center {
  align-self: center;
}

.table-style {
  vertical-align: top;
  text-align: left;
}

.selected-table-style {
  box-shadow: 0 0 0 3px #000 inset;
}

.day-table-style {
  vertical-align: center;
  text-align: left;
  background-color: #ededed;
}

.align-space-between {
  justify-content: space-between;
}

.vertical-align-center {
  vertical-align: center;
}

.horiaontal-center-align {
  align-items: center;
  display: flex;
}

.select-style {
  width: 100px;
  margin-right: 10px;
}

.select-component-style {
  width: 250px;
  align-self: center;
  margin: 20px;
  z-index: 800;
}

.select-order-component-style {
  width: 150px;
  margin-left: 20px;
  margin-bottom: 10px;
  z-index: 700;
  font-size: 12px;
}

.bottom-sheet-tip {
  padding: 8px;
  padding-top: 8px;
  position: fixed;
  width: 120px;
  height: auto;
  bottom: 45px;
  align-content: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  border-top: 1px solid #cfcfcf;
  border-right: 1px solid #cfcfcf;
  border-left: 1px solid #cfcfcf;

  transition: all 0.1s ease 0s;
  align-items: center;
}

.bottom-sheet-tip-with-only-pickups {
  padding: 8px;
  padding-top: 8px;
  position: fixed;
  width: 120px;
  height: 30px;
  bottom: 45px;
  align-content: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0 1px 1px 1px #e8e8e8 inset;
  align-items: center;
  cursor: pointer;
  transform: translateY(-109px);
  transition: transform 0.1s ease 0s;
}

.bottom-sheet-tip-with-items {
  padding: 8px;
  padding-top: 8px;
  position: fixed;
  width: 120px;
  height: 30px;
  bottom: 45px;
  align-content: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0 1px 1px 1px #e8e8e8 inset;
  align-items: center;
  cursor: pointer;
  transform: translateY(-45px);
  transition: transform 0.1s ease 0s;
}

.bottom-sheet-tip-with-items-active {
  padding: 8px;
  padding-top: 8px;
  position: fixed;
  width: 120px;
  height: 30px;
  bottom: 45px;
  align-content: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0 1px 1px 1px #e8e8e8 inset;
  align-items: center;
  cursor: pointer;
  transform: translateY(-154px);
  transition: transform 0.1s ease 0s;
}

.bottom-sheet-tip-with-items-show-buttones {
  padding: 8px;
  padding-top: 8px;
  position: fixed;
  width: 120px;
  height: auto;
  bottom: 45px;
  align-content: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
  transform: translateY(-115px);
  transition: transform 0.1s ease 0s;
}

.bottom-sheet-tip-with-items-active-show-buttones {
  padding: 8px;
  padding-top: 8px;
  position: fixed;
  width: 120px;
  height: auto;
  bottom: 45px;
  align-content: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
  transform: translateY(-115px);
  transition: transform 0.1s ease 0s;
}

.bottom-sheet-menu-tip-arrow-div {
  position: sticky;
  top: 0px;
  width: 100%;
  height: 30px;
  background-color: white;
  align-content: center;
  justify-content: center;
  text-align: center;
  align-items: center;
  transform: translateY(-10px);
}

.bottom-sheet-menu-tip {
  padding: 8px;
  padding-top: 8px;
  position: fixed;
  width: 100%;
  max-width: 600px;
  height: 30px;
  bottom: 0px;
  align-content: center;
  justify-content: center;
  text-align: center;
  align-items: center;
  transform: translateY(0px);
  transition: all 0.1s ease 0s;
}

.bottom-pickup-button {
  padding: 8px;
  padding-top: 8px;
  position: fixed;
  width: 100%;
  max-width: 600px;
  height: 30px;
  bottom: 0px;
  background-color: rgba(0, 135, 117, 0.95);
  align-content: center;
  justify-content: center;
  text-align: center;
  align-items: center;
  transform: translateY(-45px);
  transition: all 0.1s ease 0s;
}

.bottom-pickup-button-active {
  padding: 8px;
  padding-top: 8px;
  position: fixed;
  width: 100%;
  max-width: 600px;
  height: 29px;
  bottom: 0px;
  background-color: rgba(0, 135, 117, 0.95);
  align-content: center;
  justify-content: center;
  text-align: center;
  align-items: center;
  transform: translateY(0px);
  transition: all 0.1s ease 0s;
}

.bottom-sheet-menu-tip-with-items {
  padding: 8px;
  padding-top: 8px;
  position: fixed;
  width: 100%;
  max-width: 600px;
  height: 30px;
  bottom: 0px;
  align-content: center;
  justify-content: center;
  text-align: center;
  align-items: center;
  transform: translateY(-45px);
  transition: all 0.1s ease 0s;
}

.bottom-sheet-menu-tip-with-items-visible-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.bottom-sheet-menu-tip-with-items-visible::-webkit-scrollbar {
  display: none;
}

.bottom-sheet-menu-tip-with-items-invisible::-webkit-scrollbar {
  display: none;
}

.bottom-sheet-menu-tip-with-only-pickups::-webkit-scrollbar {
  display: none;
}

.bottom-sheet-menu-tip-with-items-invisible {
  overflow: auto;
  padding: 8px;
  padding-top: 13px;
  position: fixed;
  width: 100%;
  max-width: 600px;
  height: 88px;
  min-height: 30px;
  bottom: 16px;
  text-align: center;
  align-items: center;
  transform: translateY(100px);
  transition: all 0.1s ease 0s;
}

.bottom-sheet-menu-tip-with-only-pickups {
  overflow: auto;
  padding: 8px;
  padding-top: 13px;
  position: fixed;
  width: 100%;
  max-width: 600px;
  height: 88px;
  min-height: 30px;
  bottom: 16px;
  text-align: center;
  align-items: center;
  transform: translateY(-29px) translateX(-7px);
  transition: all 0.1s ease 0s;
}

.bottom-sheet-menu-tip-with-items-visible {
  overflow: auto;
  padding: 8px;
  padding-top: 13px;
  position: fixed;
  width: 100%;
  max-width: 600px;
  height: 88px;
  min-height: 30px;
  bottom: 16px;
  text-align: center;
  align-items: center;
  transform: translateY(-75px) translateX(-7px);
  transition: all 0.1s ease 0s;
}

.sheet-style {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}

.text-center {
  text-align: center;
}

.signiture-text-color {
  color: #006042;
}

.mini-badge-style {
  display: inline-flex;
  margin-left: 5px;
  margin-right: 0px;
  border-radius: 20px;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(0, 135, 117, 0.95);
  justify-content: center;
}

.background-signiture-color {
  background-color: #006042;
}

.background-signiture-color-30 {
  background-color: rgba(0, 135, 117, 0.3);
}

.background-disable-color {
  background-color: #cfcfcf;
}

.background-disable-color-with-border {
  background-color: #cfcfcf;
  border: 2px dashed black;
}

.auto-width-100-max-width {
  width: 100%;
  max-width: 600px;
  max-height: auto;
}

.content-style {
  display: inline;
  width: 400px;
}

.logo-tilt {
  width: 300px;
  height: auto;
}

.width-100-image {
  width: 100%;
  height: auto;
}

.max-height-100 {
  max-height: 100%;
}

.max-height-50 {
  max-height: 50%;
}

.add-btn-row-between {
  display: flex;
  width: 86%;
  flex-direction: row;
  justify-content: space-between;
}

.coupon-add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  width: 70%;
  height: auto;
  margin-left: 1px;
  margin-right: 1px;
  margin-top: 20px;
  margin-bottom: 1px;

  cursor: pointer;
}

.coupon-add-btn-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(0, 135, 117, 1);
  border-radius: 5px;
  width: 70%;

  height: auto;
  margin-top: 19px;
  cursor: pointer;
}

.add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  width: 43%;
  height: 170px;
  margin-left: 1px;
  margin-right: 1px;
  margin-top: 20px;
  margin-bottom: 1px;
  cursor: pointer;
}

.add-btn-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(0, 135, 117, 1);
  border-radius: 5px;
  width: 43%;

  height: 170px;
  margin-top: 19px;
  cursor: pointer;
}

.big-event-scone-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  width: 85%;
  height: 130px;
  margin-top: 20px;
  margin-bottom: 1px;
  cursor: pointer;
}

.big-event-scone-btn-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(0, 135, 117, 1);
  border-radius: 5px;
  width: 85%;
  height: 130px;
  margin-top: 19px;
  cursor: pointer;
}

.big-add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  width: 85%;
  height: 130px;
  margin-top: 20px;
  margin-bottom: 1px;
  cursor: pointer;
}

.big-add-btn-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(0, 135, 117, 1);
  border-radius: 5px;
  width: 85%;
  height: 130px;
  margin-top: 19px;
  cursor: pointer;
}

.strike {
  text-decoration: line-through;
}

.fixed-center {
  position: fixed;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.right-bottom-fixed {
  position: absolute;
  right: 10px;
  bottom: 15px;
}

.row-between-space-92 {
  display: flex;
  margin-left: 4%;
  width: 92%;
  flex-direction: row;
  justify-content: space-between;
}

.row-between-space-89 {
  display: flex;
  margin-left: 6%;
  width: 89%;
  flex-direction: row;
  justify-content: space-between;
}

.row-between-space-86 {
  display: flex;
  margin-left: 7%;
  width: 86%;
  flex-direction: row;
  justify-content: space-between;
}

.row-between-space-88 {
  display: flex;
  margin-left: 6%;
  width: 88%;
  flex-direction: row;
  justify-content: space-between;
}

.row-between-space-100 {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.z-index-100 {
  z-index: 100;
}

.z-index-900 {
  z-index: 900;
}

.first-class-process-line {
  width: 33.3%;
  position: absolute;
  left: 0;
}
.second-class-process-line {
  width: 33.3%;
  position: absolute;
  left: 33.3%;
}
.third-class-process-line {
  width: 33.3%;
  position: absolute;
  left: 66.6%;
}

.first-delivery-process-line {
  width: 25%;
  position: absolute;
  left: 0;
}
.second-delivery-process-line {
  width: 25%;
  position: absolute;
  left: 25%;
}
.third-delivery-process-line {
  width: 25%;
  position: absolute;
  left: 50%;
}
.fourth-delivery-process-line {
  width: 25%;
  position: absolute;
  left: 75%;
}

.margin-left-5 {
  margin-left: 5%;
}

.small-line-circle {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  border: 2px solid #006042;
}

.image-object-fit-50 {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.image-object-fit-76 {
  width: 76px;
  height: 76px;
  object-fit: cover;
}
.slick-track {
  display: flex;
}
.slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

.nav-badge-style {
  display: inline-flex;
  border-radius: 30px;
  width: 11px;
  height: 11px;
  padding: 5px;
  background-color: rgba(0, 135, 117, 0.95);
  justify-content: center;
  position: absolute;
  left: 55%;
  top: 2px;
  transform: scale(100%);
  transition: all 0.1s ease 0s;
}

.nav-badge-style-bigger {
  display: inline-flex;
  border-radius: 30px;
  width: 11px;
  height: 11px;
  padding: 5px;
  background-color: rgba(0, 135, 117, 0.95);
  justify-content: center;
  position: absolute;
  left: 55%;
  top: 2px;
  transform: scale(120%);
  transition: all 0.1s ease 0s;
}

.small-red-box {
  background-color: #eb4034;
  padding: 3px;
  border-radius: 2px;
}

.small-signiture-color-box {
  background-color: rgba(0, 135, 117, 0.95);
  padding: 3px;
  border-radius: 2px;
}

.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 1cm;
  padding-top: 3cm;
  margin: 0 auto; /* 가운데 정렬을 위해 추가 */
}

.subpage {
  display: flex;
  flex-direction: column;
  margin-top: 1.8cm;
  align-items: center; /* 가운데 정렬을 위해 추가 */
}

.one-fourth {
  width: 10cm;
  height: 14.3cm;
  display: flex; /* 추가 */
  justify-content: center; /* 추가 */
  position: relative; /* 추가 */
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}

#table {
  transform: rotate(90deg) scale(0.37) translateX(500px); /* translateX 제거하고 수정 */
  margin: 0 auto; /* 가운데 정렬을 위해 추가 */
}

#additional-table {
  transform: rotate(90deg) scale(0.37) translateY(393px); /* translateX 제거하고 수정 */
  margin: 0 auto; /* 가운데 정렬을 위해 추가 */
}

#td {
  height: 40px;
  min-width: 55px;
  max-width: 55px;
  word-break: break-word;
  padding: 1px 3px;
  border: 1px solid #000001;
  font-size: 10px;
  text-align: center;
  background-color: #cccccc;
  -webkit-print-color-adjust: exact;
  font-weight: bold;
  color: #000001;
}

#td-1 {
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1px 3px;
  border: 1px solid #000001;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  color: #000001;
}

#td-2 {
  height: 40px;
  min-width: 80px;
  padding: 1px 3px;
  border: 1px solid #000001;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  color: #000001;
}

#td-3 {
  height: 40px;
  min-width: 120px;
  padding: 1px 3px;
  border: 1px solid #000001;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  color: #000001;
}

#td-big-font {
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  word-break: break-word;
  padding: 1px 3px;
  border: 1px solid #000001;
  color: #000001;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.sheet-table {
  border-collapse: collapse;
  border-spacing: 0;
  position: absolute; /* 추가 */
  transform-origin: top center; /* 회전 중심점 변경 */
}
#th {
  height: 40px;
  min-width: 55px;
  max-width: 55px;
  word-break: break-word;
  padding: 1px 3px;
  border: 1px solid #000001;
  font-size: 10px;
  background-color: #cccccc;
  -webkit-print-color-adjust: exact;
  font-weight: bold;
  color: #000001;
}

#th-title {
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  word-break: break-word;
  padding: 1px 3px;
  font-size: 14px;
  font-weight: bold;
  color: #000001;
}

#th-2 {
  height: 40px;
  min-width: 80px;
  padding: 1px 3px;
  border: 1px solid #000001;
  font-size: 10px;
  background-color: #cccccc;
  -webkit-print-color-adjust: exact;
  font-weight: bold;
  color: #000001;
}
#th-3 {
  height: 40px;
  min-width: 120px;
  padding: 1px 3px;
  border: 1px solid #000001;
  font-size: 10px;
  background-color: #cccccc;
  -webkit-print-color-adjust: exact;
  font-weight: bold;
  color: #000001;
}

#th-4 {
  height: 40px;
  min-width: 120px;
  padding: 1px 3px;
  border: 1px solid #000001;
  font-size: 10px;
  background-color: #cccccc;
  -webkit-print-color-adjust: exact;
  font-weight: bold;
  color: #000001;
}

.item-order-print-container {
  transform: scale(146%) translateX(125px) translateY(380px);
}

.coupon-left-box {
  width: 110px;
  height: 120px;
  background: linear-gradient(325deg, #00bab0 30%, #00eddf 90%);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.coupon-right-box {
  width: 160px;
  height: 120px;
  background-color: #00615c;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.center-arrow {
  transform: rotate(90deg);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #39b8b1;
  position: absolute;
  top: 55px;
  left: 105px;
}

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

.qr-scanner {
  border: 10px solid #006042;
}

.coupon-used-small {
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border: 5px solid #ff4040;
  transform: rotate(-15deg) translateX(-65px) translateY(-70px);
  transition: all 0.3s ease 0s;
}

.coupon-used-big {
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border: 5px solid #ff4040;
  transform: rotate(-15deg) translateX(-65px) translateY(-70px) scale(150%);
}

.loading-animation1 {
  opacity: 1;
  transition: all 3s ease 0s;
}

.loading-animation2 {
  opacity: 0;
}

.qr-reader-container {
  width: 100%;
  margin-top: 30px;
}

.z-index-600 {
  z-index: 600;
}

.small-round-divider {
  width: 30px;
  border-radius: 10px;
}

.min-height-100 {
  min-height: 150%;
}

.sticky-under-header {
  height: 66px;
  background-color: white;
  z-index: 500;
}

.sticky-tabs {
  height: 43px;
  background-color: white;
  z-index: 500;
}

.overflow-wrap-text {
  display: flex;
  overflow-wrap: break-word;
}

.going-up-down-container {
  display: flex;
  position: sticky;
  bottom: 20px;
  right: 10px;
  width: auto;
  margin-left: auto;
  border-radius: 100px;
  flex-direction: column;
  z-index: 300;
}

.going-up-button {
  display: flex;
  width: 35px;
  height: 35px;
  margin-bottom: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  z-index: 300;
  cursor: pointer;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.going-down-button {
  display: flex;
  height: 35px;
  width: 35px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  z-index: 300;
  cursor: pointer;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.review-image-open {
  opacity: 1;
  transition: all 0.3s ease 0s;
}

.review-image-close {
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  width: 92%;
  max-width: 600px;
  margin-left: 4%;

  scroll-behavior: smooth;

  .card {
    flex: 0 0 auto;
  }
}

.scrollmenu-parent {
  width: 97%;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 560px;
  overflow: hidden;
}

.scrollmenu {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  white-space: nowrap;

  scroll-behavior: smooth;

  .card {
    flex: 0 0 auto;
  }
}

.review-scrollmenu-parent {
  width: 95%;
  margin-left: 12px;
  margin-right: 12px;
  max-width: 560px;
  height: 100%;
  overflow: hidden;
}

.photo-review {
  width: auto;
  height: 220px;
  cursor: pointer;
  border-radius: 10px;
}

.photo-review-detail {
  width: 250px;
  max-height: 300px;
}

.photo-review-div {
  width: 90%;
  padding: 4%;
}

.photo-reviews-div {
  width: 90%;
  padding: 4%;
  margin-top: 15px;
}

.recommend-scrollmenu-parent {
  width: 97%;
  margin-left: 5px;
  margin-right: 5px;
  max-width: 560px;
}

.recommend-scrollmenu {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  white-space: nowrap;

  scroll-behavior: smooth;

  .card {
    flex: 0 0 auto;
  }
}

.scrollmenu-child {
}

.left-round-button {
  position: absolute;
  display: flex;
  left: 20px;
  top: 70px;
  z-index: 10;
  background-color: white;
  border-radius: 35px;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
}

.right-round-button {
  position: absolute;
  display: flex;
  right: 20px;
  top: 70px;
  z-index: 10;
  background-color: white;
  border-radius: 35px;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
}

.arrow-gray {
  font-size: 12px;
  background-color: gray;
}

.item-card-container {
  width: 100%;
  align-content: center;
  flex-direction: row;
  display: flex;
}

.item-card {
  width: 50%;
  margin: 4%;
  margin-top: 1%;
  margin-bottom: 1%;
  height: auto;
  border-radius: 10px;
  cursor: pointer;
}
